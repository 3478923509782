import {useCallback, useEffect} from 'react';
import {ApolloError, useMutation} from '@apollo/client';
import calculateProductionCostMutation from 'Pages/ProductionCost/graphql/calculateProductionCostMutation';
import {convertPercentageToDecimal} from 'Pages/Common/Utils';
import {
  setCalculateProductionCostErrors,
  productionCostCreationIsPending,
  receivedCalculateProductionCost
} from 'Pages/ProductionCost/store/slice';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {
  BusInputValidationErrorExtensions,
  InvalidProductionCostBusScheduleCostFactorErrorExtensions,
  InvalidProductionCostBusScheduleProvidedValueErrorExtensions,
  ScheduleInputValidationErrorExtensions
} from '../../types';
import {CalculateProductionCostErrors} from 'Pages/ProductionCost/store/types';

const useCalculateProductionCost = () => {
  const productionCostEntryId = useAppSelector(
    ({productionCost}) => productionCost.id
  );
  const driverCostCalculationType = useAppSelector(
    ({productionCost}) => productionCost.driverCostCalculationType
  );
  const leasingCostCalculationType = useAppSelector(
    ({productionCost}) => productionCost.leasingCostCalculationType
  );
  const busValues = useAppSelector(
    ({productionCost}) => productionCost.busValues
  );
  const busScheduleValues = useAppSelector(
    ({productionCost}) => productionCost.busScheduleValues
  );

  const dispatch = useAppDispatch();

  const [calculateProductionCost, {data, error}] = useMutation(
    calculateProductionCostMutation,
    // Both data and error.graphQLErrors are populated
    {errorPolicy: 'all'}
  );

  const handleErrors = useCallback(
    (error: ApolloError) => {
      const errors: CalculateProductionCostErrors = {
        invalidProvidedValues: [],
        invalidCostFactors: [],
        other: []
      };

      for (const err of error.graphQLErrors) {
        const errorType = err.extensions?.errorType as string;

        switch (errorType) {
          case 'InvalidProductionCostBusScheduleProvidedValueError': {
            errors.invalidProvidedValues.push(
              err.extensions as any as InvalidProductionCostBusScheduleProvidedValueErrorExtensions
            );
            break;
          }
          case 'InvalidProductionCostBusScheduleCostFactorError': {
            errors.invalidCostFactors.push(
              err.extensions as any as InvalidProductionCostBusScheduleCostFactorErrorExtensions
            );
            break;
          }
          case 'BusInputValidationError': {
            errors.other.push(
              err.extensions as any as BusInputValidationErrorExtensions
            );
            break;
          }
          case 'ScheduleInputValidationError': {
            errors.other.push(
              err.extensions as any as ScheduleInputValidationErrorExtensions
            );
            break;
          }
          default: {
            // can be InternalErrorExtensions, GraphQLValidationError, etc
            errors.other.push({
              id: Math.random().toString(),
              errorMessage: err.message
            });
          }
        }
      }

      dispatch(setCalculateProductionCostErrors(errors));
    },
    [dispatch]
  );

  useEffect(() => {
    // Initial state
    if (!data && !error) return;

    if (error) {
      handleErrors(error);
    }

    if (data?.calculateProductionCost) {
      dispatch(receivedCalculateProductionCost(data.calculateProductionCost));
    }
  }, [data, error, handleErrors, dispatch]);

  const calculate = useCallback(async () => {
    dispatch(productionCostCreationIsPending());

    const busProvidedValuesEntries = busValues?.map(bus => ({
      busPartnerBusTypeCostEntryId: bus.busPartnerBusTypeCostEntryId,
      busPartnerId: bus.busPartnerId,
      countryBusTypeCostEntryId: bus.countryBusTypeCostEntryId,
      countryRulesCountryId: bus.countryRulesCountryId,
      vehicleId: bus.vehicleId
    }));

    const busScheduleProvidedValuesEntries = busScheduleValues?.flatMap(
      ({busScheduleProvidedValuesEntries}) =>
        busScheduleProvidedValuesEntries?.map(vehicleEntries => ({
          accommodationCostPerNight: vehicleEntries.accommodationCostPerNight,
          accommodationsPerWeek: vehicleEntries.accommodationsPerWeek,
          additionalCostPerTrip: vehicleEntries.additionalCostPerTrip,
          busUtilizationFactor: convertPercentageToDecimal(
            vehicleEntries.busUtilizationFactor
          ),
          countryDriverConceptTypeId: vehicleEntries.countryDriverConceptTypeId,
          driverSalary: vehicleEntries.driverSalary,
          emptyHrPerDay: vehicleEntries.emptyHrPerDay,
          emptyKmPerDay: vehicleEntries.emptyKmPerDay,
          flixStandardCostOverwrite: vehicleEntries.flixStandardCostOverwrite,
          id: vehicleEntries.id,
          numberOfDrivers: vehicleEntries.numberOfDrivers,
          replacementHrPerDay: vehicleEntries.replacementHrPerDay,
          replacementKmPerDay: vehicleEntries.replacementKmPerDay,
          salaryChange: vehicleEntries.salaryChange,
          totalDriverCostOverwrite: vehicleEntries.totalDriverCostOverwrite
        }))
    );

    await calculateProductionCost({
      variables: {
        productionCostEntryId,
        driverCostCalculationType,
        leasingCostCalculationType,
        busProvidedValuesEntries,
        busScheduleProvidedValuesEntries
      }
    });
  }, [
    calculateProductionCost,
    busScheduleValues,
    driverCostCalculationType,
    leasingCostCalculationType,
    productionCostEntryId,
    busValues,
    dispatch
  ]);

  return calculate;
};

export default useCalculateProductionCost;
