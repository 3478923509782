import {datadogRum} from '@datadog/browser-rum';
import {isDevOrStagingEnvironment} from 'Pages/Common/Utils';

export const initDataDog = () => {
  if (isDevOrStagingEnvironment) {
    return;
  }

  const datadogEnv = process.env.REACT_APP_ENV || 'development';

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.eu',
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    env: datadogEnv,
    version: process.env.REACT_APP_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    actionNameAttribute: 'data-action-name',
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
    allowedTracingUrls: ['https://cpc.bdts.flix.tech']
  });

  datadogRum.setGlobalContext({
    'fxt.productdomain': 'gbx',
    'fxt.productline': 'bdts',
    'fxt.product': 'cpc'
  });
};
