import React, {useMemo} from 'react';
import {Divider, Grid, GridCol, Text} from '@flixbus/honeycomb-react';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import DriverCostCalculationTypes from 'Pages/ProductionCost/constants/driverCostCalculationTypes';
import {useVehicleCurrency} from 'Pages/ProductionCost/components/DataImportStepTwo/hooks/useVehicleCurrency';
import styles from './LineScheduleVehicleDriverCosts.module.scss';
import {LineScheduleVehicleDriverCostsProps} from './LineScheduleVehicleDriverCosts.types';
import DriverConcept from './DriverConcept/DriverConcept';
import EmptyHoursPerOperatingDay from './EmptyHoursPerOperatingDay/EmptyHoursPerOperatingDay';
import AccommodationsPerWeek from './AccommodationsPerWeek/AccommodationsPerWeek';
import AccommodationCostPerNight from './AccommodationCostPerNight/AccommodationCostPerNight';
import DriverChangeDistancePerOperatingDay from './DriverChangeDistancePerOperatingDay/DriverChangeDistancePerOperatingDay';
import DriverChangeHoursPerOperatingDay from './DriverChangeHoursPerOperatingDay/DriverChangeHoursPerOperatingDay';
import SalaryChange from './SalaryIncreaseDecrease/SalaryChange';
import TotalDriverCostOverwrite from './TotalDriverCostOverwrite/TotalDriverCostOverwrite';
import NumberOfDrivers from './NumberOfDrivers/NumberOfDrivers';
import AnnualDriverSalary from './AnnualDriverSalary/AnnualDriverSalary';

const LineScheduleVehicleDriverCosts: React.FC<
  LineScheduleVehicleDriverCostsProps
> = ({vehicleEntry, errors}) => {
  const driverCostCalculationType = useAppSelector(
    ({productionCost}) => productionCost.driverCostCalculationType
  );
  const countryRulesCountryId = useAppSelector(
    ({productionCost}) =>
      productionCost.busValues?.find(
        ({vehicleId}) => vehicleId === vehicleEntry.vehicleId
      )?.countryRulesCountryId
  );
  const currency = useVehicleCurrency(vehicleEntry.vehicleId) ?? '???';

  const renderFields = useMemo(() => {
    switch (driverCostCalculationType) {
      case DriverCostCalculationTypes.DRIVER_SCHEDULE_HOURS.value:
        return (
          <>
            <Grid>
              <GridCol size={4}>
                <DriverConcept
                  value={vehicleEntry.countryDriverConceptTypeId}
                  entryId={vehicleEntry.id}
                  countryRulesCountryId={countryRulesCountryId}
                  errors={errors?.countryDriverConceptTypeId}
                />
              </GridCol>
            </Grid>

            <Grid>
              <GridCol size={4}>
                <EmptyHoursPerOperatingDay
                  value={vehicleEntry.emptyHrPerDay}
                  entryId={vehicleEntry.id}
                />
              </GridCol>

              <GridCol size={4}>
                <AccommodationsPerWeek
                  value={vehicleEntry.accommodationsPerWeek}
                  entryId={vehicleEntry.id}
                />
              </GridCol>

              <GridCol size={4}>
                <AccommodationCostPerNight
                  value={vehicleEntry.accommodationCostPerNight}
                  entryId={vehicleEntry.id}
                  currency={currency}
                  errors={errors?.accommodationCostPerNight}
                />
              </GridCol>
            </Grid>

            <Grid>
              <GridCol size={4}>
                <DriverChangeDistancePerOperatingDay
                  value={vehicleEntry.replacementKmPerDay}
                  entryId={vehicleEntry.id}
                />
              </GridCol>

              <GridCol size={4}>
                <DriverChangeHoursPerOperatingDay
                  value={vehicleEntry.replacementHrPerDay}
                  entryId={vehicleEntry.id}
                />
              </GridCol>

              <GridCol size={4}>
                <SalaryChange
                  value={vehicleEntry.salaryChange}
                  entryId={vehicleEntry.id}
                  currency={currency}
                />
              </GridCol>
            </Grid>
          </>
        );

      case DriverCostCalculationTypes.DRIVER_COST_OVERWRITE.value:
        return (
          <>
            <Grid>
              <GridCol size={4}>
                <TotalDriverCostOverwrite
                  value={vehicleEntry.totalDriverCostOverwrite}
                  entryId={vehicleEntry.id}
                  currency={currency}
                  errors={errors?.totalDriverCostOverwrite}
                />
              </GridCol>
            </Grid>

            <Grid>
              <GridCol size={4}>
                <AccommodationsPerWeek
                  value={vehicleEntry.accommodationsPerWeek}
                  entryId={vehicleEntry.id}
                />
              </GridCol>

              <GridCol size={4}>
                <AccommodationCostPerNight
                  value={vehicleEntry.accommodationCostPerNight}
                  entryId={vehicleEntry.id}
                  currency={currency}
                  errors={errors?.accommodationCostPerNight}
                />
              </GridCol>
            </Grid>
          </>
        );

      case DriverCostCalculationTypes.DRIVER_SALARY.value:
        return (
          <>
            <Grid>
              <GridCol size={4}>
                <NumberOfDrivers
                  value={vehicleEntry.numberOfDrivers}
                  entryId={vehicleEntry.id}
                  errors={errors?.numberOfDrivers}
                />
              </GridCol>

              <GridCol size={4}>
                <AnnualDriverSalary
                  value={vehicleEntry.driverSalary}
                  entryId={vehicleEntry.id}
                  currency={currency}
                  errors={errors?.driverSalary}
                />
              </GridCol>
            </Grid>

            <Grid>
              <GridCol size={4}>
                <AccommodationsPerWeek
                  value={vehicleEntry.accommodationsPerWeek}
                  entryId={vehicleEntry.id}
                />
              </GridCol>

              <GridCol size={4}>
                <AccommodationCostPerNight
                  value={vehicleEntry.accommodationCostPerNight}
                  entryId={vehicleEntry.id}
                  currency={currency}
                  errors={errors?.accommodationCostPerNight}
                />
              </GridCol>
            </Grid>
          </>
        );

      default:
        return null;
    }
  }, [
    driverCostCalculationType,
    vehicleEntry,
    countryRulesCountryId,
    currency,
    errors
  ]);

  return (
    <div className={styles.container}>
      <Text>
        <b>2. Driver costs</b>
      </Text>
      <Divider />
      <div className={styles.inputs}>{renderFields}</div>
      <Divider />
    </div>
  );
};

export default React.memo(LineScheduleVehicleDriverCosts);
